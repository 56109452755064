import { Middleware } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { openModal } from '@common/components/modal/modal.slice';
import { CONFIRMATION_ACTION_TYPE } from '@common/components/modal/constants';

export const debounceMiddleware: Middleware = () => {
  return (next) => (action: any) => {
    if (!action.meta?.delay) {
      return next(action);
    }
    const debouncedAction = debounce(next, action.meta.delay);
    return debouncedAction(action);
  };
};

export const withDelay = (action: any, delay: number) => {
  return {
    ...action,
    meta: { delay },
  };
};

export const confirmationMiddleware: Middleware = ({ dispatch }) => {
  return (next) => (action: any) => {
    if (action.type !== CONFIRMATION_ACTION_TYPE) {
      return next(action);
    }
    const { title, content, cancelFn, actionType, actionPayload, callbackFn } =
      action.meta.modalOptions;
    const confirmAction = { type: actionType, payload: actionPayload };
    dispatch(openModal({ title, content, confirmAction, cancelFn, callbackFn }));
  };
};
