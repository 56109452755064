import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IPagination } from '@administration/interfaces/IPagination';
import { ISurveyRequest } from '@administration/interfaces/ISurvey';

const surveyBaseApi = `${config.REACT_APP_API_VERSION}/surveys`;
export const getSurveys = ({ pageSize, offset }: IPagination): Promise<AxiosResponse> => {
  return instance.get(surveyBaseApi, {
    params: {
      page_size: pageSize,
      offset: offset,
      $count: true,
    },
  });
};

export const getSurvey = (surveyId: string, extend = true): Promise<AxiosResponse> => {
  const url = extend
    ? `${surveyBaseApi}/${surveyId}?extend=[answers]`
    : `${surveyBaseApi}/${surveyId}`;
  return instance.get(url);
};

export const filterSurveys = ({ pageSize, offset, query }: IPagination): Promise<AxiosResponse> => {
  return instance.get(`${surveyBaseApi}/filter`, {
    params: {
      page_size: pageSize,
      offset: offset,
      $count: true,
      query: query,
    },
  });
};

export const postSurvey = (data: ISurveyRequest): Promise<AxiosResponse> => {
  return instance.post(surveyBaseApi, data);
};

export const deleteSurvey = (surveyId: string): Promise<AxiosResponse> => {
  return instance.delete(`${surveyBaseApi}/${surveyId}`);
};

export const putSurvey = (surveyId: string, data: ISurveyRequest): Promise<AxiosResponse> => {
  return instance.put(`${surveyBaseApi}/${surveyId}`, data);
};
