import { createSlice } from '@reduxjs/toolkit';
import { IApprovedDocsState } from '@administration/interfaces/IApprovedDocs';

const initialState: IApprovedDocsState = {
  active: false,
  approvedEmail: null,
  frequency: 'once',
  startDate: null,
  endDate: null,
};

const ApprovedEmailSlice = createSlice({
  name: 'usecase/approved_email',
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setApprovedEmail: (state, action) => {
      state.approvedEmail = action.payload;
    },
    clearApprovedEmail: (state) => {
      state.active = initialState.active;
      state.approvedEmail = initialState.approvedEmail;
      state.frequency = initialState.frequency;
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    prepareApprovedEmailForEdit: (state, action) => {
      const { active, approvedEmail, frequency, startDate, endDate } = action.payload;
      state.active = active;
      state.approvedEmail = approvedEmail;
      state.frequency = frequency;
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },
});

export const {
  setActive,
  setApprovedEmail,
  setFrequency,
  clearApprovedEmail,
  setStartDate,
  setEndDate,
  prepareApprovedEmailForEdit,
} = ApprovedEmailSlice.actions;
export default ApprovedEmailSlice.reducer;
