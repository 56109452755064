import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiStatus } from '@administration/interfaces/IApi';
import { fetchSurvey, submitResponses } from '../actions';
import { ISurveyResponse } from '@administration/interfaces/ISurvey';
import { IAnswer, IUserAnswer } from '@administration/interfaces/IAnswers';

export interface IDissmisState {
  survey: {
    data: ISurveyResponse | null;
    loading: IApiStatus['loading'];
    error: IApiStatus['error'];
  };
  responses: {
    data: IUserAnswer[] | null;
    loading: IApiStatus['loading'];
    error: IApiStatus['error'];
  };
}

const initialState: IDissmisState = {
  survey: {
    data: null,
    loading: 'idle',
    error: null,
  },
  responses: {
    data: null,
    loading: 'idle',
    error: null,
  },
};

const SuggestionDismissSlice = createSlice({
  name: 'suggestionDismiss',
  initialState,
  reducers: {
    toggleResponse(state, action: PayloadAction<{ id: string; checked: boolean }>) {
      const { id, checked } = action.payload;
      const currentAnswer = state.responses.data?.find((answer) => answer.id === id);
      if (currentAnswer) {
        currentAnswer.checked = checked;
      }
    },
    setResponses(state, action: PayloadAction<{ id: string; value: string | null }>) {
      const { id, value } = action.payload;
      const currentAnswer = state.responses.data?.find((answer) => answer.id === id);
      if (currentAnswer) {
        currentAnswer.value = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurvey.pending, (state) => {
        state.survey.loading = 'pending';
        state.survey.error = null;
      })
      .addCase(fetchSurvey.fulfilled, (state, action) => {
        const survey = action.payload;
        state.survey.loading = 'succeeded';
        const answers = survey.questions[0].answers as IAnswer[];
        if (survey?.are_questions_ordered) {
          answers?.sort((a, b) => a.order - b.order);
        }
        state.responses.data = answers?.map((answer) => ({
          ...answer,
          id: answer.id,
          value: null,
          checked: false,
        }));
        state.survey.data = survey;
        state.survey.error = null;
      })
      .addCase(fetchSurvey.rejected, (state, action) => {
        state.survey.loading = 'failed';
        state.survey.error = action.error.message || 'Failed to fetch survey';
      })
      .addCase(submitResponses.pending, (state) => {
        state.responses.loading = 'pending';
        state.responses.error = null;
      })
      .addCase(submitResponses.fulfilled, (state) => {
        state.responses.loading = 'succeeded';
        state.responses.error = null;
      })
      .addCase(submitResponses.rejected, (state, action) => {
        state.responses.loading = 'failed';
        state.responses.error = action.error.message || 'Failed to submit responses';
      });
  },
});

export const { toggleResponse, setResponses } = SuggestionDismissSlice.actions;
export default SuggestionDismissSlice.reducer;
