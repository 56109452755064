import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialStateFilters } from '../../../../../interfaces/IAdminUsers';

const initialState: IInitialStateFilters = {
  filterOptions: [
    {
      name: 'ADMIN_FILTERS_USERNAME',
      query: 'user_name',
      value: false,
    },
    {
      name: 'ADMIN_FILTERS_FAMILYNAME',
      query: 'family_name',
      value: true,
    },
    {
      name: 'ADMIN_FILTERS_EMAIL',
      query: 'mail',
      value: false,
    },
    {
      name: 'ADMIN_FILTERS_GIVENNAME',
      query: 'given_name',
      value: true,
    },
  ],
};

const userAdminFiltersSlice = createSlice({
  name: 'userAdminFiltersSlice',
  initialState,
  reducers: {
    handleFilters: (state, action: PayloadAction<{ name: string; type: string }>) => {
      state.filterOptions = state.filterOptions.map((option: any) =>
        option.name === action.payload.name
          ? { ...option, value: action.payload.type === 'toggle' ? !option.value : false }
          : option,
      );
    },
  },
});

export const { handleFilters } = userAdminFiltersSlice.actions;
export default userAdminFiltersSlice.reducer;
