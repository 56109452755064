import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { IProductResponse } from '@administration/interfaces/IProduct';

interface QueryParams {
  page_size?: number;
  offset?: number;
  country_id?: string;
}

export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['Products', 'CountryProducts'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getProducts: build.query<IProductResponse[], QueryParams>({
      query: (params = { page_size: -1, offset: 0 }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/products`,
        params,
      }),
      providesTags: (result) =>
        result
          ? ['Products', ...result.map(({ id }) => ({ type: 'Products' as const, id }))]
          : ['Products'],
    }),
    getProduct: build.query<IProductResponse, string>({
      query: (id) => ({
        pathname: `${config.REACT_APP_API_VERSION}/products/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: 'Products', id: result.id }] : []),
    }),
    getCountryProducts: build.query<
      IProductResponse[],
      { countryId?: string; enableSelectAll?: boolean; params?: QueryParams }
    >({
      query: ({ countryId, params = { page_size: -1, offset: 0 } }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/products`,
        params: {
          ...params,
          country_id: countryId,
        },
      }),
      transformResponse: (response: { values: IProductResponse[] }, _, { enableSelectAll }) => {
        if (enableSelectAll) {
          response.values.unshift({ id: 'ALL', name: 'All' } as IProductResponse);
        }
        return response.values;
      },
      providesTags: (_result, _error, { countryId }) => {
        return [{ type: 'CountryProducts', id: `products-${countryId}` }];
      },
    }),
  }),
});

export const { useGetProductsQuery, useGetProductQuery, useGetCountryProductsQuery } = productApi;
