import { t } from 'i18next';
import { setRequestError } from '../slices/veeva-integration.slice';
import {
  IAuthentication,
  ICapablities,
  IIntegrationFormState,
} from '@administration/interfaces/IVeeva';
import { displayToast } from '@administration/components/toast/toast.slice';

export const validateEntries = (
  data: IIntegrationFormState['integrationForm'],
  dispatch: any,
  editMode?: boolean,
) => {
  if (!data.id && editMode) {
    dispatch(displayToast({ keyMessage: 'GLOBAL_ERROR', type: 'error' }));
    return false;
  }
  if (data.name === 'Veeva SFTP' && !data.authentication.username) {
    dispatch(
      setRequestError({
        type: 'username',
        message: t('GLOBAL_REQUIRED', { context: t('SFTP_USER') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva SFTP' && !data.authentication.password) {
    dispatch(
      setRequestError({
        type: 'password',
        message: t('GLOBAL_REQUIRED', { context: t('SFTP_PASSWORD') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva SFTP' && !data.capabilities.hostname) {
    dispatch(
      setRequestError({
        type: 'hostname',
        message: t('GLOBAL_REQUIRED', { context: t('HOSTNAME') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva SFTP' && !data.capabilities.port) {
    dispatch(
      setRequestError({ type: 'port', message: t('GLOBAL_REQUIRED', { context: t('PORT') }) }),
    );
    return false;
  }
  if (data.name === 'Veeva SFTP' && !data.capabilities.path) {
    dispatch(
      setRequestError({ type: 'path', message: t('GLOBAL_REQUIRED', { context: t('PATH') }) }),
    );
    return false;
  }
  if (data.name === 'Veeva API' && !data.capabilities.base_url) {
    dispatch(
      setRequestError({
        type: 'baseUrl',
        message: t('GLOBAL_REQUIRED', { context: t('BASE_URL') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva API' && !data.authentication.clientId) {
    dispatch(
      setRequestError({
        type: 'clientId',
        message: t('GLOBAL_REQUIRED', { context: t('CLIENT_ID') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva API' && !data.authentication.clientSecret) {
    dispatch(
      setRequestError({
        type: 'clientSecret',
        message: t('GLOBAL_REQUIRED', { context: t('CLIENT_SECRET') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva API' && !data.capabilities.token_url) {
    dispatch(
      setRequestError({
        type: 'tokenUrl',
        message: t('GLOBAL_REQUIRED', { context: t('TOKEN_URL') }),
      }),
    );
    return false;
  }
  if (data.name === 'Veeva API' && !data.capabilities.auth_grant_type.scope) {
    dispatch(
      setRequestError({
        type: 'scope',
        message: t('GLOBAL_REQUIRED', { context: t('GLOBAL_SCOPE') }),
      }),
    );
    return false;
  }

  return true;
};

export const extractAuthentication = (data: { authentication: IAuthentication }) => ({
  clientId: data?.authentication?.clientId || '',
  clientSecret: data?.authentication?.clientSecret || '',
  username: data?.authentication?.username || '',
  password: data?.authentication?.password || '',
  token: data?.authentication?.token || '',
  apiKey: data?.authentication?.apiKey || '',
});

export const extractCapabilities = (data: { capabilities: ICapablities }) => ({
  port: data?.capabilities?.port || 22,
  path: data?.capabilities?.path || '',
  hostname: data?.capabilities?.hostname || '',
  token_url: data?.capabilities?.token_url || '',
  base_url: data?.capabilities?.base_url || '',
  auth_grant_type: {
    grant_type: data?.capabilities?.auth_grant_type?.grant_type || 'client_credentials',
    scope: data?.capabilities?.auth_grant_type?.scope || '',
  },
});
