import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSurvey, getSurvey, putSurvey, filterSurveys } from '@administration/apis/survey';
import { IPagination } from '@administration/interfaces/IPagination';
import { MRT_RowSelectionState } from 'material-react-table';
import { removeSurveyLocally, updateSurveyLocally } from '../slices/survey.slice';
import { updateSurveyForm } from '../slices/survey-form.slice';
import { getAnswers } from '@administration/apis/answers';
import { displayToast } from '@administration/components/toast/toast.slice';
import { ISurveyRequest, TypeSurveyStatus } from '@administration/interfaces/ISurvey';

export const fetchSurveys = createAsyncThunk(
  'surveys/fetchSurveys',
  async ({ pageSize, offset, query }: IPagination) => {
    const response = await filterSurveys({ pageSize, offset, query });
    return response.data;
  },
);

export const fetchSurvey = createAsyncThunk(
  'surveys/fetchSurvey',
  async (surveyId: string, { dispatch }) => {
    const response = await getSurvey(surveyId);
    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to fetch survey');
    }
    const questionId = response.data.questions[0].id;
    const answersResponse = await getAnswers(surveyId, questionId);
    if (answersResponse.status < 200 || answersResponse.status >= 300) {
      throw new Error('Failed to fetch answers');
    }

    dispatch(
      updateSurveyForm({
        survey: response.data,
        answers: answersResponse.data.values,
      }),
    );
    return response.data;
  },
);

export const removeSurvey = createAsyncThunk(
  'surveys/removeSurveyItems',
  async (payload: string | MRT_RowSelectionState, { dispatch }) => {
    const surveyIds = typeof payload === 'string' ? [payload] : Object.keys(payload);

    await Promise.all(
      surveyIds.map((id) =>
        deleteSurvey(id)
          .then(() => {
            dispatch(removeSurveyLocally(id));
            dispatch(displayToast({ keyMessage: 'GLOBAL_DELETE_SUCCESS', type: 'success' }));
          })
          .catch(() =>
            dispatch(displayToast({ keyMessage: 'GLOBAL_DELETE_FAILED', type: 'error' })),
          ),
      ),
    );
    return surveyIds;
  },
);

export const updateSurvey = createAsyncThunk(
  'surveys/updateSurvey',
  async ({ id: surveyId, status }: { id: string; status: TypeSurveyStatus }, { dispatch }) => {
    const response = await putSurvey(surveyId, { status } as ISurveyRequest);
    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to update survey');
    }
    dispatch(
      updateSurveyLocally({
        surveyId,
        status,
      }),
    );
    dispatch(displayToast({ keyMessage: 'GLOBAL_UPDATED_SUCCESSFULLY', type: 'success' }));
    return response.data;
  },
);
