import { ProtectedRoute } from '../common/components/authentication/protected';
import { PermittedRoute } from '../common/components/permissions/permittedRoute';
import Loadable from '../common/components/loadable';
import { lazyWithRetry } from '../utils/lazy-retry';

const Suggestions = Loadable(
  lazyWithRetry(() => import('../common/pages/suggestions/suggestions')),
);
const PersonalSettings = Loadable(lazyWithRetry(() => import('../common/pages/settings/personal')));
const UserLayout = Loadable(lazyWithRetry(() => import('../common/components/layout/user-layout')));
const DismissPage = Loadable(lazyWithRetry(() => import('../common/pages/dismiss-page')));

export const UserRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <PermittedRoute accessRules={{ roles: ['PUBLIC'] }}>
        <UserLayout />
      </PermittedRoute>
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Suggestions mode="web" />,
    },
    {
      path: '/suggestions',
      element: <Suggestions mode="web" />,
    },
    {
      path: '/dismiss/:surveyId',
      element: <DismissPage mode="web" />,
    },
    {
      path: 'settings',
      element: <PersonalSettings mode="web" />,
    },
  ],
};
