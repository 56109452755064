import { displayToast } from '@administration/components/toast/toast.slice';
import { AnswerPayload, IAction, IUserAnswer } from '@administration/interfaces/IAnswers';
import { Value } from '@common/interfaces/ISuggestions';

export const validateEntries = (responses: IUserAnswer[] | null, dispatch: any) => {
  if (!responses?.length) {
    return false;
  }
  const isAtLeastOneChecked = responses.some((answer) => answer.checked);
  const isFreeTextCheckedAndHasValue = responses
    .filter((answer) => answer.is_free_text && answer.checked)
    .every((answer) => answer.value);
  if (!isAtLeastOneChecked) {
    dispatch(displayToast({ type: 'error', keyMessage: 'AT_LEAST_ONE_QUESTION_REQUIRED' }));
    return false;
  } else if (!isFreeTextCheckedAndHasValue) {
    dispatch(displayToast({ type: 'error', keyMessage: 'ALL_FREE_TEXT_QUESTIONS_REQUIRED' }));
    return false;
  }
  return true;
};

export const getDelayInDays = (delay: IAction['delay']) => {
  if (delay.weeks) {
    return delay.weeks * 7;
  }
  if (delay.days) {
    return delay.days;
  }
  return 0;
};

export const sortAnswers = (answers: IUserAnswer[] | null) => {
  if (!answers || !Array.isArray(answers) || answers.length === 0) {
    return [];
  }
  return [...answers].sort((a: IUserAnswer, b: IUserAnswer) => {
    if (!a.action && !b.action) {
      return 0;
    }
    if (!a.action) {
      return 1;
    }
    if (!b.action) {
      return -1;
    }

    const delayA = getDelayInDays(a.action.delay);
    const delayB = getDelayInDays(b.action.delay);
    return delayA - delayB;
  });
};

export const createAnswerPayload = (
  suggestion: Value,
  userAnswer: IUserAnswer,
  userLang: string,
): AnswerPayload => {
  // suggestion.id
  const basePayload = { suggestion_value_id: 'dd007212-9a50-436d-83a8-dc9e209f8fd2' };

  if (userAnswer.is_free_text) {
    return {
      ...basePayload,
      answer: userAnswer.value as string,
      lang: userLang,
    };
  }

  return basePayload;
};

export const handleRedirect = (): void => {
  setTimeout(() => {
    window.location.href = '/';
  }, 3000);
};
