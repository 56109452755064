import { createAsyncThunk } from '@reduxjs/toolkit';
import { validate as isValidUUID } from 'uuid';

import { postSurvey, putSurvey } from '@administration/apis/survey';
import { postQuestion, putQuestion } from '@administration/apis/questions';
import { TypeSurveyStatus } from '@administration/interfaces/ISurvey';
import { deleteAnswer, postAnswer, putAnswers } from '@administration/apis/answers';
import { RootState } from '@store/types';
import { preparePayload, validateEntries } from '../helpers';
import { displayToast } from '@administration/components/toast/toast.slice';
import { clearSurveyForm } from '../slices/survey-form.slice';
import { fetchSurvey } from './survey.actions';

export const createSurvey = createAsyncThunk(
  'surveysForm/createSurvey',
  async ({ status }: { status: TypeSurveyStatus }, { getState, dispatch }) => {
    const { surveyForm } = getState() as RootState;

    const isEntriesValid = validateEntries(surveyForm, status, dispatch);
    if (!isEntriesValid) {
      return;
    }

    const { surveyPayload, questionPayload, answersPayload } = preparePayload(surveyForm, status);

    const surveyResponse = await postSurvey(surveyPayload);
    if (surveyResponse.status < 200 || surveyResponse.status >= 300) {
      throw new Error('Failed to create survey');
    }

    const surveyId = surveyResponse.data.id;

    const questionResponse = await postQuestion(surveyId, questionPayload);
    if (questionResponse.status < 200 || questionResponse.status >= 300) {
      throw new Error('Failed to create question');
    }

    const questionId = questionResponse.data.id;

    for (const answer of answersPayload) {
      answer.id = null;
      const answerResponse = await postAnswer(surveyId, questionId, answer);
      if (answerResponse.status < 200 || answerResponse.status >= 300) {
        throw new Error(`Failed to create answer for ${JSON.stringify(answer)}`);
      }
    }
    dispatch(displayToast({ keyMessage: 'GLOBAL_CREATED_SUCCESSFULLY', type: 'success' }));
    dispatch(clearSurveyForm());
    window.location.href = `/administration/settings/survey/${surveyId}/edit`;
    return surveyResponse.data;
  },
);

export const updateSurvey = createAsyncThunk(
  'surveysForm/updateSurvey',
  async ({ status }: { status: TypeSurveyStatus }, { getState, dispatch }) => {
    const { surveyForm } = getState() as RootState;
    const isEntriesValid = validateEntries(surveyForm, status, dispatch);
    if (!isEntriesValid) {
      return;
    }
    const { surveyPayload, questionPayload, answersPayload } = preparePayload(surveyForm, status);

    const surveyId = surveyForm.surveyId;
    if (!surveyId) {
      return;
    }

    const questionId = surveyForm.Questions.id;
    const surveyResponse = await putSurvey(surveyId, surveyPayload);
    if (surveyResponse.status < 200 || surveyResponse.status >= 300) {
      throw new Error('Failed to create survey');
    }

    const questionResponse = await putQuestion(surveyId, questionId, questionPayload);
    if (questionResponse.status < 200 || questionResponse.status >= 300) {
      throw new Error('Failed to create question');
    }

    for (const answer of answersPayload) {
      if (answer?.id && isValidUUID(answer?.id)) {
        const answerResponse = await putAnswers(surveyId, questionId, answer.id, answer);
        if (answerResponse.status < 200 || answerResponse.status >= 300) {
          throw new Error(`Failed to create answer for ${JSON.stringify(answer)}`);
        }
      } else {
        answer.id = null;
        const answerResponse = await postAnswer(surveyId, questionId, answer);
        if (answerResponse.status < 200 || answerResponse.status >= 300) {
          throw new Error(`Failed to create answer for ${JSON.stringify(answer)}`);
        }
      }
    }
    dispatch(fetchSurvey(surveyId));
    dispatch(displayToast({ keyMessage: 'GLOBAL_UPDATED_SUCCESSFULLY', type: 'success' }));
    return surveyResponse.data;
  },
);

export const removeAnswer = createAsyncThunk(
  'surveysForm/removeAnswer',
  async (
    { surveyId, questionId, answerId }: { surveyId: string; questionId: string; answerId: string },
    { dispatch },
  ) => {
    const response = await deleteAnswer(surveyId, questionId, answerId);
    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to remove answer');
    }
    dispatch(displayToast({ keyMessage: 'GLOBAL_DELETE_SUCCESS', type: 'success' }));
    return response.data;
  },
);
