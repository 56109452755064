import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IQuestionRequest } from '../../interfaces/IQuestions';

export const getSurveyQuestion = (surveyId: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions`);
};

export const getQuestion = (surveyId: string, questionId: string): Promise<AxiosResponse> => {
  return instance.get(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}`,
  );
};

export const postQuestion = (surveyId: string, data: IQuestionRequest): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions`, data);
};

export const putQuestion = (
  surveyId: string,
  questionId: string,
  data: IQuestionRequest,
): Promise<AxiosResponse> => {
  return instance.put(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}`,
    data,
  );
};
