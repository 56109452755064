import { createSlice } from '@reduxjs/toolkit';
import { createSurvey, updateSurvey } from '../actions/survey-form.actions';
import { fetchSurvey } from '../actions/survey.actions';
import { ISurveyFormState } from '@administration/interfaces/ISurvey';

const initialState: ISurveyFormState = {
  country: null,
  countryId: null,
  activeCountries: [],
  selectedLanguage: '',
  surveyName: '',
  surveyId: null,
  status: 'draft',
  answers: [],
  Questions: {
    id: '',
    are_answers_single_choice: false,
    are_answers_ordered: false,
    keys: [],
  },
  loading: 'idle',
  error: null,
};

const SurveyFormSlice = createSlice({
  name: 'surveyForm',
  initialState,
  reducers: {
    clearSurveyForm(state) {
      Object.assign(state, initialState);
    },
    setField(
      state: ISurveyFormState,
      action: { payload: { field: keyof ISurveyFormState; value: any } },
    ) {
      const { field, value } = action.payload;

      if (field in state) {
        state[field] = value;
      }
    },
    addAnswer(state, action) {
      state.answers.push(action.payload);
    },
    updateAnswerKey(state, action) {
      const { answerId, dst_lng, dst_txt } = action.payload;
      state.answers = state.answers.map((answer) => {
        if (answer.id === answerId) {
          return {
            ...answer,
            keys: answer.keys.map((key) => (key.dst_lng === dst_lng ? { ...key, dst_txt } : key)),
          };
        }
        return answer;
      });
    },
    clearAnswers(state) {
      state.answers = [];
    },
    updateAnswer(state, action) {
      const { answerId, updatedAnswer } = action.payload;
      state.answers = state.answers.map((answer) =>
        answer.id === answerId ? { ...answer, ...updatedAnswer } : answer,
      );
    },
    deleteAnswer(state, action) {
      const answerId = action.payload;
      state.answers = state.answers.filter((answer) => answer.id !== answerId);
    },
    updateAnswerOrder: (state, action) => {
      const newOrder = action.payload;
      state.answers = newOrder
        .map((id: string, index: number) => {
          const answer = state.answers.find((a) => a.id === id);
          if (answer) {
            answer.order = index;
          }
          return answer;
        })
        .filter(Boolean);
    },
    toggleAnswerDelay(state, action) {
      const { answerId, isEnabled } = action.payload;
      const answer = state.answers.find((ans) => ans.id === answerId);
      if (answer) {
        answer.action = isEnabled ? { delay: { days: 0, weeks: 0 } } : null;
      }
    },
    updateDelay(state, action) {
      const { answerId, delayType, delayValue } = action.payload;
      const answer = state.answers.find((ans) => ans.id === answerId);

      if (answer) {
        if (!delayType) {
          answer.action = null;
        } else {
          answer.action = {
            delay: {
              [delayType]: delayValue,
            },
          };
        }
      }
    },
    setSurveyFormError(state, action) {
      state.error = action.payload;
    },
    updateSurveyForm(state, action) {
      const { survey, answers } = action.payload;
      state.countryId = survey.country_id;
      state.surveyName = survey.name;
      state.selectedLanguage = survey.primary_language;
      state.surveyId = survey.id;
      state.status = survey.status;
      if (survey.questions[0].are_answers_ordered) {
        answers.sort((a: any, b: any) => a.order - b.order);
      }
      state.answers = answers;
      state.Questions = {
        id: survey.questions[0].id,
        are_answers_single_choice: survey.questions[0].are_answers_single_choice,
        are_answers_ordered: survey.questions[0].are_answers_ordered,
        keys: answers.map((answer: any) => answer.keys),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSurvey.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createSurvey.fulfilled, (state) => {
      state.loading = 'succeeded';
    });
    builder.addCase(createSurvey.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.error.message || 'CAN_NOT_CREATE_SURVEY';
    });
    builder.addCase(updateSurvey.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(updateSurvey.fulfilled, (state) => {
      state.loading = 'succeeded';
    });
    builder.addCase(updateSurvey.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.error.message || 'CAN_NOT_CREATE_SURVEY';
    });
    builder.addCase(fetchSurvey.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchSurvey.fulfilled, (state) => {
      state.loading = 'succeeded';
    });
    builder.addCase(fetchSurvey.rejected, (state) => {
      state.loading = 'failed';
    });
  },
});

export const {
  clearSurveyForm,
  setField,
  addAnswer,
  updateAnswer,
  updateAnswerKey,
  toggleAnswerDelay,
  clearAnswers,
  updateAnswerOrder,
  deleteAnswer,
  updateDelay,
  setSurveyFormError,
  updateSurveyForm,
} = SurveyFormSlice.actions;

export default SurveyFormSlice.reducer;
