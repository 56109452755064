import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@store/types';
import { selectToast } from '@store/selectors';
import { hideToast } from './toast.slice';
import { t } from 'i18next';

export const Toast = () => {
  const { isOpen, type, message, keyMessage, position, title, duration, closeCallback } =
    useSelector(selectToast);
  const dispatch: AppDispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={position}
      open={isOpen}
      autoHideDuration={duration}
      onClose={() => (closeCallback ? closeCallback() : dispatch(hideToast()))}
    >
      <Alert
        onClose={() => dispatch(hideToast())}
        severity={type}
        variant="filled"
        sx={{ width: '100%', zIndex: 9999 }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message && message}
        {keyMessage && t(keyMessage)}
      </Alert>
    </Snackbar>
  );
};
