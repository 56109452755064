import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSurvey } from '@administration/apis/survey';
import { createAnswerPayload, handleRedirect, sortAnswers, validateEntries } from '../helpers';
import { AnswerPayload, SubmitResponsesParams } from '@administration/interfaces/IAnswers';
import { ISurveyResponse } from 'administration/interfaces/ISurvey';
import { RootState } from '@store/types';
import { postUserAnswers } from '@administration/apis/answers';
import { displayToast } from '@administration/components/toast/toast.slice';
import { dismissSuggestion } from '@common/apis/suggestions';

export const fetchSurvey = createAsyncThunk<ISurveyResponse, string>(
  'suggestionDismiss/getSurvey',
  async (surveyId: string) => {
    const response = await getSurvey(surveyId);
    return response.data;
  },
);

const submitAnswer = async (
  survey: ISurveyResponse,
  answerId: string,
  payload: AnswerPayload,
): Promise<void> => {
  await postUserAnswers(survey.id, survey.questions[0].id, answerId, payload);
};

export const submitResponses = createAsyncThunk(
  'suggestionDismiss/submitResponses',
  async ({ survey, suggestion, userLang }: SubmitResponsesParams, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { responses } = state.suggestionDismiss;

      if (!validateEntries(responses.data, dispatch)) {
        return;
      }

      const sortedAnswers = sortAnswers(responses.data);
      const checkedAnswers = sortedAnswers.filter((answer) =>
        responses?.data?.find((userAnswer) => userAnswer.id === answer.id && userAnswer.checked),
      );
      await Promise.all(
        checkedAnswers.map(async (answer) => {
          const userAnswer = responses?.data?.find((userResponse) => userResponse.id === answer.id);

          if (userAnswer) {
            const payload = createAnswerPayload(suggestion, userAnswer, userLang);
            await submitAnswer(survey, answer.id, payload);
          }
        }),
      );

      await dismissSuggestion(suggestion.id);

      dispatch(
        displayToast({
          type: 'success',
          keyMessage: 'RESPONSES_SAVED_SUCCESSFULLY',
          closeCallback: () => handleRedirect(),
        }),
      );
    } catch (error) {
      dispatch(
        displayToast({
          type: 'error',
          keyMessage: 'GLOBAL_ERROR',
        }),
      );
      throw error;
    }
  },
);
