import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { IMappedTable, IMappedTableResponse } from '@administration/interfaces/IVeeva';

export const MappersApi = createApi({
  reducerPath: 'MappersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMappedTables: builder.query({
      query: ({ crm }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/crms/${crm}/mappings/tables`,
      }),
      transformResponse: (response: IMappedTableResponse) => {
        if (!response?.tables?.length) {
          return {};
        }

        return response.tables.reduce(
          (acc: Record<string, IMappedTable[]>, table: IMappedTable) => {
            if (!table.destination_table || !table.crm_table) {
              return acc;
            }

            const category = table.category;
            if (!acc[category]) {
              acc[category] = [];
            }

            const exists = acc[category].some(
              (t) =>
                t.destination_table === table.destination_table && t.crm_table === table.crm_table,
            );

            if (!exists) {
              acc[category].push(table);
            }
            return acc;
          },
          {},
        );
      },
    }),
    getMappers: builder.query({
      query: ({ crm_table, destination_table }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/crms/veeva/mappings/search`,
        params: { crm_table, destination_table, page_size: 1000 },
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetMappedTablesQuery, useGetMappersQuery } = MappersApi;
