import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchAudiencesMembers } from '../actions/audience.action';
import { ICountryResponse } from '@administration/interfaces/ICountry';
import { IProductResponse } from '@administration/interfaces/IProduct';
import { IAudiences } from '@administration/interfaces/IUsecases';
import {
  extractCountryFromAudience,
  extractCountryIdFromAudience,
  extractProductFromAudience,
  extractProductIdFromAudience,
} from '../utils';

interface IUsecaseAudienceState {
  country: ICountryResponse | null;
  product: IProductResponse | null;
  audienceMembersCount: number | string;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
  audienceId?: string;
}

const initialState: IUsecaseAudienceState = {
  country: null,
  product: null,
  audienceMembersCount: 0,
  loading: 'idle',
  error: null,
  audienceId: undefined,
};

const usecaseAudience = createSlice({
  name: 'usecase/audience',
  initialState,
  reducers: {
    clearAudience: (state) => {
      state.country = null;
      state.product = null;
      state.audienceMembersCount = 0;
      state.loading = 'idle';
      state.error = null;
      state.audienceId = undefined;
    },
    setAudienceId: (state, action) => {
      state.audienceId = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    prepareAudienceForEdit: (state, action: PayloadAction<IAudiences>) => {
      state.audienceId = action.payload.id;
      state.country = {
        name: extractCountryFromAudience(action.payload),
        id: extractCountryIdFromAudience(action.payload),
      } as ICountryResponse;
      state.product = {
        name: extractProductFromAudience(action.payload),
        id: extractProductIdFromAudience(action.payload),
      } as IProductResponse;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAudiencesMembers.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchAudiencesMembers.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.audienceMembersCount = action.payload?.membersCount || 0;
    });
    builder.addCase(fetchAudiencesMembers.rejected, (state, action) => {
      state.audienceMembersCount = 'N/A';
      state.loading = 'failed';
      state.error = action.error.message || 'CAN_NOT_FETCH_AUDIENCE_MEMBERS';
    });
  },
});

export const { clearAudience, setAudienceId, setCountry, setProduct, prepareAudienceForEdit } =
  usecaseAudience.actions;
export default usecaseAudience.reducer;
