import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createProvider, fetchProviders, updateProvider } from '../actions';
import { IIntegrationFormState, TypeGrantType } from '@administration/interfaces/IVeeva';
import { extractAuthentication, extractCapabilities } from '../helpers';

const initialState: IIntegrationFormState = {
  integrationForm: {
    id: null,
    name: 'Veeva SFTP',
    source: 'VEEVA',
    connection: 'SFTP',
    usecase: 'DATA_SYNC',
    authentication: {
      clientId: '',
      clientSecret: '',
      username: '',
      password: '',
      token: '',
      apiKey: '',
    },
    capabilities: {
      port: 22,
      path: '',
      hostname: '',
      token_url: '',
      base_url: '',
      auth_grant_type: {
        grant_type: 'client_credentials',
        scope: '',
      },
    },
  },
  integrationRequest: {
    loading: 'idle',
    error: null,
  },
};

const VeevaIntegration = createSlice({
  name: 'veevaIntegration',
  initialState,
  reducers: {
    setIntegrationName: (state, action: PayloadAction<'Veeva API' | 'Veeva SFTP'>) => {
      const id = state.integrationForm.id;
      state.integrationForm.name = action.payload;
      if (action.payload === 'Veeva SFTP') {
        state.integrationForm = {
          ...initialState.integrationForm,
          id,
          name: 'Veeva SFTP',
          source: 'VEEVA',
          connection: 'SFTP',
          usecase: 'DATA_SYNC',
        };
      } else {
        state.integrationForm = {
          ...initialState.integrationForm,
          id,
          name: 'Veeva API',
          source: 'VEEVA',
          connection: 'API',
          usecase: 'DATA_SYNC',
        };
      }
    },
    clearIntegrationForm: (state) => {
      state.integrationForm.authentication = initialState.integrationForm.authentication;
      state.integrationForm.capabilities = initialState.integrationForm.capabilities;
    },
    setAuthentication: (
      state,
      action: PayloadAction<{
        key: keyof typeof state.integrationForm.authentication;
        value: string;
      }>,
    ) => {
      const { key, value } = action.payload;
      state.integrationForm.authentication[key] = value;
    },
    setCapabilities: (
      state,
      action: PayloadAction<{
        key: Exclude<keyof typeof state.integrationForm.capabilities, 'authGrantType'>;
        value: string | number;
      }>,
    ) => {
      const { key, value } = action.payload;
      if (typeof value === typeof state.integrationForm.capabilities[key]) {
        state.integrationForm.capabilities[key] = value as never;
      }
    },
    setGrantType: (state, action: PayloadAction<TypeGrantType>) => {
      state.integrationForm.capabilities.auth_grant_type.grant_type = action.payload;
    },
    setScope: (state, action: PayloadAction<string>) => {
      state.integrationForm.capabilities.auth_grant_type.scope = action.payload;
    },
    setRequestError: (state, action: PayloadAction<{ type: string; message: string }>) => {
      state.integrationRequest.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProviders.fulfilled, (state, action) => {
      const responseData = action.payload[0];
      state.integrationForm = {
        id: responseData?.id || null,
        name: responseData.name,
        source: responseData.source,
        connection: responseData.connection,
        usecase: responseData.usecase,
        authentication: extractAuthentication(responseData),
        capabilities: extractCapabilities(responseData),
      };
      state.integrationRequest.loading = 'succeeded';
    });
    builder.addCase(fetchProviders.rejected, (state, action) => {
      state.integrationForm = initialState.integrationForm;
      state.integrationRequest.loading = 'failed';
      state.integrationRequest.error = {
        type: 'error',
        message: action.error.message || '',
      };
    });
    builder.addCase(fetchProviders.pending, (state) => {
      state.integrationRequest.loading = 'pending';
    });
    // create provider
    builder.addCase(createProvider.fulfilled, (state, action) => {
      state.integrationForm = action.payload;
      state.integrationRequest.error = null;
      state.integrationRequest.loading = 'succeeded';
    });
    builder.addCase(createProvider.rejected, (state) => {
      state.integrationRequest.loading = 'failed';
    });
    builder.addCase(createProvider.pending, (state) => {
      state.integrationRequest.loading = 'pending';
    });
    // update provider
    builder.addCase(updateProvider.fulfilled, (state, action) => {
      state.integrationForm = action.payload;
      state.integrationRequest.error = null;
      state.integrationRequest.loading = 'succeeded';
    });
    builder.addCase(updateProvider.rejected, (state) => {
      state.integrationRequest.loading = 'failed';
    });
    builder.addCase(updateProvider.pending, (state) => {
      state.integrationRequest.loading = 'pending';
    });
  },
});

export const {
  setIntegrationName,
  setAuthentication,
  setCapabilities,
  setGrantType,
  setScope,
  clearIntegrationForm,
  setRequestError,
} = VeevaIntegration.actions;
export default VeevaIntegration.reducer;
