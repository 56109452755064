import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSurveys, getSurvey } from '@administration/apis/survey';

export const fetchSurveys = createAsyncThunk(
  'usecasePanel/fetchSurveys',
  async (_, { rejectWithValue }) => {
    const response = await getSurveys({ pageSize: -1, offset: 0 });
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue('GLOBAL_FETCHING_ERROR');
    }
    return response.data.values;
  },
);

export const fetchSurvey = createAsyncThunk(
  'usecasePanel/fetchSurvey',
  async (surveyId: string | null, { rejectWithValue }) => {
    if (!surveyId) {
      return rejectWithValue('GLOBAL_FETCHING_ERROR');
    }
    const response = await getSurvey(surveyId, false);
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue('GLOBAL_FETCHING_ERROR');
    }
    return response.data;
  },
);
