import { t } from 'i18next';

import {
  CALL_INVITED,
  CALL_ATTENDANCE,
  EVENT_FOLLOWUP,
  EVENT_SECOND_FOLLOWUP,
  NAMES_REGEX,
  OPTIONS,
  COUNTRY_REGEX,
  PRODUCT_REGEX,
} from '../constants';
import {
  IAudiences,
  IPayload,
  TypeTimeline,
  TypeCallTarget,
  ENotificationSentence,
  ENotificationTitle,
} from '@administration/interfaces/IUsecases';
import { IUser } from '@common/interfaces/IUser';

const POST_OPTIONS = OPTIONS.map((option) => option.name);

export const preparePayload = (args: any): IPayload => {
  const {
    isCreation,
    usecasePanel,
    usecaseSurvey,
    event,
    usecaseAudience,
    usecaseUserManager,
    usecaseMarketShare,
    usecaseParameters,
    usecaseApprovedEmails,
  } = args;
  const rules = prepareRules(
    usecaseAudience.product.id,
    usecaseAudience.country.id,
    usecaseUserManager.usersToBeInclude.values,
    usecaseUserManager.usersToBeExclude.values,
  );
  const audiences: IAudiences[] = [
    {
      name: `${usecaseAudience.country.name} - ${usecaseAudience.product.name}`,
      status: 'enabled',
      rule: rules,
    },
  ];

  if (!isCreation) {
    audiences[0]['id'] = usecaseAudience.audienceId;
  }

  const payload = {
    name: `Campaign,${usecaseAudience.country.name} - ${usecaseAudience.product.name}`,
    description: `${usecaseAudience.product.name} campaign for ${usecaseAudience.country.name}`,
    status: isCreation ? 'enabled' : usecasePanel.status,
    tags: [usecaseAudience.product.name, usecaseAudience.country.name],
    audiences: audiences,
    flow: {
      name: 'configuration_type_call_pacing',
      status: 'enabled',
      configuration: {
        mccp: {
          name: 'NBA_CONFIGURATION_MCCP_COVERAGE',
          active: usecaseParameters['NBA_CONFIGURATION_MCCP_COVERAGE'].active,
          value: null,
          can_be_modified: false,
        },
        priorities: {
          name: 'NBA_CONFIGURATION_PRIORITIES',
          active: usecaseParameters['NBA_CONFIGURATION_PRIORITIES'].active,
          value: null,
          can_be_modified: false,
        },
        role: {
          name: 'NBA_CONFIGURATION_ROLE',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_ROLE'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_ROLE'].active,
          values: POST_OPTIONS,
        },
        relative_strength: {
          name: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_RELATIVE_STRENGTH'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_RELATIVE_STRENGTH'].active,
          values: POST_OPTIONS,
        },
        adoption_ladder: {
          name: 'NBA_CONFIGURATION_ADOPTION_LADDER',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_ADOPTION_LADDER'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_ADOPTION_LADDER'].active,
          values: POST_OPTIONS,
        },
        top_accounts: {
          name: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_TOP_ACCOUNTS'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_TOP_ACCOUNTS'].active,
          values: POST_OPTIONS,
        },
        hcp_specialty: {
          name: 'NBA_CONFIGURATION_HCP_SPECIALTY',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_HCP_SPECIALTY'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_HCP_SPECIALTY'].active,
          values: POST_OPTIONS,
        },
        hcp_type: {
          name: 'NBA_CONFIGURATION_HCP_TYPE',
          can_be_modified: true,
          value: usecaseParameters['NBA_CONFIGURATION_HCP_TYPE'].selectedOption,
          active: usecaseParameters['NBA_CONFIGURATION_HCP_TYPE'].active,
          values: POST_OPTIONS,
        },
        market_share: {
          name: 'NBA_CONFIGURATION_MARKET_SHARE',
          can_be_modified: true,
          value: usecaseMarketShare.selectedOption,
          active: usecaseMarketShare.active,
          values: POST_OPTIONS,
          market_share_values: {
            type: usecaseMarketShare.settingType,
            activity_thresholds: {
              minimal_activity: {
                value: usecaseMarketShare.activity_thresholds.minimal_activity.value,
                type: usecaseMarketShare.activity_thresholds.minimal_activity.type,
              },
              low_alert_level: usecaseMarketShare.activity_thresholds.low_alert_level,
              high_alert_level: usecaseMarketShare.activity_thresholds.high_alert_level,
            },
          },
        },
        survey: {
          name: 'NBA_CONFIGURATION_SURVEY',
          can_be_modified: true,
          value: { surveyId: usecaseSurvey.selectedSurvey.value?.id || '' },
          active: usecaseSurvey.active,
        },
        event: {
          name: 'NBA_CONFIGURATION_MCCP_EVENT',
          can_be_modified: true,
          value: null,
          active: event.event.active,
          values: POST_OPTIONS,
          configurations: {
            events: {
              types: event.event.types,
              statuses: event.event.statuses,
              topics: event.event.topics,
              product_ids:
                event.event.products.values && event.event.products.values.length > 0
                  ? event.event.products.values.map((product: any) => product.id)
                  : [usecaseAudience.product.id],
            },
            calls: [
              {
                idx: 0,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.event.configurations[CALL_INVITED].days,
                title: 'NBA_EVENT_TITLE_INVITED' as ENotificationTitle.EVENT_INVITED,
                sentence: 'NBA_EVENT_REASON_INVITED' as ENotificationSentence.EVENT_INVITED,
                active: event.event.configurations[CALL_INVITED].active,
                priorities: event.event.configurations[CALL_INVITED].priorities,
                attendee_status: event.event.configurations[CALL_INVITED].attendee_status,
              },
              {
                idx: 1,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.event.configurations[CALL_ATTENDANCE].days,
                title: 'NBA_EVENT_TITLE_ACCEPTED' as ENotificationTitle.EVENT_ACCEPTED,
                sentence: 'NBA_EVENT_REASON_ACCEPTED' as ENotificationSentence.EVENT_ACCEPTED,
                active: event.event.configurations[CALL_ATTENDANCE].active,
                priorities: event.event.configurations[CALL_ATTENDANCE].priorities,
                attendee_status: event.event.configurations[CALL_ATTENDANCE].attendee_status,
              },
              {
                idx: 0,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.event.configurations[EVENT_FOLLOWUP].active,
                days: event.event.configurations[EVENT_FOLLOWUP].days,
                priorities: event.event.configurations[EVENT_FOLLOWUP].priorities,
                attendee_status: event.event.configurations[EVENT_FOLLOWUP].attendee_status,
              },
              {
                idx: 1,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.event.configurations[EVENT_SECOND_FOLLOWUP].active,
                days: event.event.configurations[EVENT_SECOND_FOLLOWUP].days,
                priorities: event.event.configurations[EVENT_SECOND_FOLLOWUP].priorities,
                attendee_status: event.event.configurations[EVENT_SECOND_FOLLOWUP].attendee_status,
              },
            ],
            approved_emails: [],
          },
        },
        approved_email: {
          name: 'NBA_CONFIGURATION_MCCP_APPROVED_EMAIL',
          can_be_modified: true,
          value: null,
          active: usecaseApprovedEmails.active,
          configuration: {
            approved_email_id: usecaseApprovedEmails.approvedEmail?.id,
            frequency: usecaseApprovedEmails.frequency,
            start_date: usecaseApprovedEmails.startDate,
            end_date: usecaseApprovedEmails.endDate,
          },
        },
      },
    },
  };
  return payload;
};

const prepareRules = (
  productId: string,
  countryId: string,
  usersToInclude: IUser[] | null,
  usersToExclude: IUser[] | null,
) => {
  let ruleExpression = `user.product.id==${productId} AND user.country.id==${countryId}`;

  const includedUsersRule =
    usersToInclude && usersToInclude.map((user: IUser) => `user.id == ${user.id}`).join(' OR ');

  const excludedUsersRule =
    usersToExclude && usersToExclude.map((user: IUser) => `user.id != ${user.id}`).join(' AND ');

  if (excludedUsersRule) {
    ruleExpression = `${ruleExpression} AND ${excludedUsersRule}`;
  }

  if (includedUsersRule) {
    ruleExpression = `${ruleExpression} OR ${includedUsersRule}`;
  }

  return ruleExpression;
};

export const extractCountryFromAudience = (audience: IAudiences) => {
  const matches = audience.name.match(NAMES_REGEX);
  return matches ? matches[1] : 'N/A';
};

export const extractProductFromAudience = (audience: IAudiences) => {
  const matches = audience.name.match(NAMES_REGEX);
  return matches ? matches[2] : 'N/A';
};

export const extractJourneyFromFlow = (flow: any) => {
  const journeyName = flow.name.replace('configuration_type_', '').toUpperCase();
  return journeyName === 'CALL_PACING' ? t('GLOBAL_CALL_PACING') : t('GLOBAL_EVENT_CONGRES');
};

export const extractCountryIdFromAudience = (audience: IAudiences) => {
  const matches = audience.rule.match(COUNTRY_REGEX);
  return matches ? matches[1] : null;
};

export const extractProductIdFromAudience = (audience: IAudiences) => {
  const matches = audience.rule.match(PRODUCT_REGEX);
  return matches ? matches[1] : null;
};

export const validateEntries = (args: any, dispatch: any) => {
  const { usecaseAudience, usecaseMarketShare, usecaseApprovedEmails } = args;
  if (!usecaseAudience.country?.id) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_COUNTRY_REQUIRED' },
    });
    return false;
  }
  if (!usecaseAudience.product?.id) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_PRODUCT_REQUIRED' },
    });
    return false;
  }
  if (
    usecaseMarketShare.settingType === 'SPECIFIC' &&
    (!usecaseMarketShare.activity_thresholds.minimal_activity.value ||
      !usecaseMarketShare.activity_thresholds.low_alert_level ||
      !usecaseMarketShare.activity_thresholds.high_alert_level)
  ) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_MARKET_SHARE_REQUIRED_INPUTS' },
    });
    return false;
  }
  if (usecaseApprovedEmails.active && !usecaseApprovedEmails.approvedEmail) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_APPROVED_EMAIL_REQUIRED' },
    });
    return false;
  }
  return true;
};
