import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchDashboardAdoptionOverview,
  fetchDashboardAdoptionUseCase,
  fetchDashboardAdoptionUseKam,
} from './overview.slice';
import { FilterName, FilterType } from '@administration/interfaces/IDashboardAdoption';

import { RootState } from '@store/types';

const initialState: {
  filterState: Record<FilterName, { name: string; selectedFilters: any }>;
  filterType: string;
  debug: boolean;
} = {
  filterState: {
    products: { name: 'products', selectedFilters: [] },
    countries: { name: 'countries', selectedFilters: [] },
    date: {
      name: 'date',
      selectedFilters: {
        type: 'last_year',
        date: {
          start_date: null,
          end_date: null,
        },
      },
    },
  },
  filterType: 'overview',
  debug: false,
};

export const onSubmitFilterDashboardAdoptionOverview = createAsyncThunk(
  'dashboardAdoptionOverviewFilter/onSubmitFilterDashboardAdoptionOvervie',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const filters = {
      products: state.dashboardAdoptionOverviewFilter.filterState.products.selectedFilters.map(
        (item: any) => item.id,
      ),
      countries: state.dashboardAdoptionOverviewFilter.filterState.countries.selectedFilters.map(
        (item: any) => item.id,
      ),
      date: {
        type: state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.type,
        date: {
          start_date:
            state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.date.start_date,
          end_date:
            state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.date.end_date,
        },
      },
    };

    if (
      filters.countries.length > 0 &&
      (filters.date.type !== 'custom' ||
        (filters.date.type === 'custom' &&
          filters.date.date.start_date &&
          filters.date.date.end_date))
    ) {
      switch (state.dashboardAdoptionOverviewFilter.filterType) {
        case 'overview':
          await dispatch(
            fetchDashboardAdoptionOverview({
              filters,
              debug: state.dashboardAdoptionOverviewFilter.debug,
            }),
          );
          break;
        case 'usecase':
          await dispatch(
            fetchDashboardAdoptionUseCase({
              filters,
              debug: state.dashboardAdoptionOverviewFilter.debug,
            }),
          );
          break;
        case 'kam':
          await dispatch(
            fetchDashboardAdoptionUseKam({
              filters,
              debug: state.dashboardAdoptionOverviewFilter.debug,
            }),
          );
          break;
        default:
          break;
      }
    }
  },
);

const dashboardAdoptionOverviewFilterSlice = createSlice({
  name: 'dashboardAdoptionOverviewFilter',
  initialState,
  reducers: {
    setFilter: (
      state,
      action: PayloadAction<{
        filter: any;
        filterName: FilterName;
        filterType: FilterType;
      }>,
    ) => {
      switch (action.payload.filterName) {
        case 'countries':
        case 'products':
          state.filterState[action.payload.filterName].selectedFilters = action.payload.filter;
          break;
        case 'date':
          if (action.payload.filterType === 'normal') {
            state.filterState.date.selectedFilters.type = action.payload.filter;
          } else {
            state.filterState.date.selectedFilters.type = 'custom';
            state.filterState.date.selectedFilters.date.start_date =
              action.payload.filter.start_date;
            state.filterState.date.selectedFilters.date.end_date = action.payload.filter.end_date;
          }
          break;
        default:
          break;
      }
    },
    setFilterType: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
    setDebug: (state, action: PayloadAction<boolean>) => {
      state.debug = action.payload;
    },
  },
});

export const { setFilter, setFilterType, setDebug } = dashboardAdoptionOverviewFilterSlice.actions;
export default dashboardAdoptionOverviewFilterSlice.reducer;
