import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProviders, postProvider, putProvider } from '@administration/apis/providers';
import { RootState } from '@store/types';
import { validateEntries } from '../helpers';
import { displayToast } from '@administration/components/toast/toast.slice';
import { t } from 'i18next';

export const fetchProviders = createAsyncThunk(
  'veevaIntegration/fetchProviders',
  async (_, { rejectWithValue }) => {
    const response = await getProviders({
      source: 'VEEVA',
      usecase: 'DATA_SYNC',
    });
    if (response.status === 404) {
      return rejectWithValue('no_providers');
    }
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue('fetch_faild');
    }
    return response.data;
  },
);

export const createProvider = createAsyncThunk(
  'veevaIntegration/createProvider',
  async (_, { getState, dispatch }) => {
    const { integrationForm } = (getState() as RootState).veevaIntegration;
    const isEntriesValid = validateEntries(integrationForm, dispatch);
    if (!isEntriesValid) {
      throw new Error();
    }
    const response = await postProvider(integrationForm);
    if (response.status < 200 || response.status >= 300) {
      dispatch(
        displayToast({ message: t('GLOBAL_SAVING_ERROR', { context: t('VEEVA') }), type: 'error' }),
      );
      throw new Error();
    }
    dispatch(displayToast({ keyMessage: 'GLOBAL_SAVED_SUCCESSFULLY', type: 'success' }));
    return response.data;
  },
);

export const updateProvider = createAsyncThunk(
  'veevaIntegration/updateProvider',
  async (_, { getState, dispatch }) => {
    const { integrationForm } = (getState() as RootState).veevaIntegration;
    const isEntriesValid = validateEntries(integrationForm, dispatch, true);
    if (!isEntriesValid) {
      throw new Error();
    }
    const response = await putProvider(integrationForm.id as string, integrationForm);
    if (response.status < 200 || response.status >= 300) {
      dispatch(
        displayToast({ message: t('GLOBAL_SAVING_ERROR', { context: t('VEEVA') }), type: 'error' }),
      );
      throw new Error();
    }
    dispatch(displayToast({ keyMessage: 'GLOBAL_SAVED_SUCCESSFULLY', type: 'success' }));
    return response.data;
  },
);
