import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { ICountryResponse } from '@administration/interfaces/ICountry';
import { extractCountryFromAddress } from '@common/helpers/addresses';

export const CountryApi = createApi({
  reducerPath: 'CountryApi',
  tagTypes: ['activeCountries'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getActiveCountries: builder.query<ICountryResponse[], { enableSelectAll?: boolean } | void>({
      query: () => ({
        pathname: `${config.REACT_APP_API_VERSION}/countries`,
        params: { $isActivated: true },
      }),
      transformResponse: (response: { values: ICountryResponse[] }, _, args) => {
        const isCountryAdmin = localStorage.getItem('user_role')?.includes('country');
        if (isCountryAdmin) {
          const userCountry = extractCountryFromAddress();
          if (!userCountry) return [];
          response.values = response.values.filter((country) => country.id === userCountry.id);
        }
        if (args?.enableSelectAll && !isCountryAdmin) {
          response.values.unshift({ id: 'ALL', name: 'All' } as ICountryResponse);
        }
        return response.values;
      },
      providesTags: ['activeCountries'],
    }),
    toggleCountryActivation: builder.mutation<ICountryResponse, { country: ICountryResponse }>({
      query: ({ country }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/countries/${country.id}/${country.status === 'enabled' ? 'deactivate' : 'activate'}`,
        method: 'POST',
      }),
      invalidatesTags: ['activeCountries'],
    }),
  }),
});

export const { useGetActiveCountriesQuery, useToggleCountryActivationMutation } = CountryApi;
