import { createSlice } from '@reduxjs/toolkit';
import { IApiStatus } from '@administration/interfaces/IApi';
import { fetchSurveys, removeSurvey } from '../actions/survey.actions';
import { ISurveyResponse, TypeSurveyStatus } from '@administration/interfaces/ISurvey';

interface ISurvey {
  surveys: ISurveyResponse[];
  filters: { status: TypeSurveyStatus[] };
  count: number;
  loading: IApiStatus['loading'];
  error: IApiStatus['error'];
}

const initialState: ISurvey = {
  surveys: [],
  filters: { status: ['draft', 'published'] },
  count: 0,
  loading: 'idle',
  error: null,
};

export const Survey = createSlice({
  name: 'survey',
  initialState: initialState,
  reducers: {
    removeSurveyLocally(state, action) {
      const surveyId = action.payload;
      state.surveys = state.surveys.filter((survey) => survey.id !== surveyId);
    },
    updateSurveyLocally(state, action) {
      const { surveyId, status } = action.payload;
      const toUpdateSurvey = state.surveys.find((survey) => survey.id === surveyId);
      if (toUpdateSurvey) {
        state.surveys.map((survey) => {
          if (survey.id === surveyId) {
            survey.status = status;
          }
          return survey;
        });
      }
    },
    manageFilters(state, action) {
      const { status } = action.payload;
      if (state.filters.status.includes(status)) {
        state.filters.status = state.filters.status.filter((s) => s !== status);
      } else {
        state.filters.status.push(status);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveys.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSurveys.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.surveys = action.payload.values;
        state.count = action.payload['$count'];
      })
      .addCase(fetchSurveys.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'CAN_NOT_FETCH_SURVEYS';
      })
      .addCase(removeSurvey.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(removeSurvey.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(removeSurvey.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'CAN_NOT_DELETE_SURVEYS';
      });
  },
});

export const { removeSurveyLocally, updateSurveyLocally, manageFilters } = Survey.actions;
export default Survey.reducer;
