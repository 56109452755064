import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IAnswerRequest, IResponseAnswer } from '../../interfaces/IAnswers';

export const postAnswer = (
  surveyId: string,
  questionId: string,
  data: IAnswerRequest,
): Promise<AxiosResponse> => {
  return instance.post(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}/answers`,
    data,
  );
};

export const getAnswers = (surveyId: string, questionId: string): Promise<AxiosResponse> => {
  return instance.get(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}/answers`,
  );
};

export const putAnswers = (
  surveyId: string,
  questionId: string,
  answerId: string,
  data?: IAnswerRequest | IResponseAnswer,
): Promise<AxiosResponse> => {
  return instance.put(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}/answers/${answerId}`,
    data,
  );
};

export const deleteAnswer = (
  surveyId: string,
  questionId: string,
  answerId: string,
): Promise<AxiosResponse> => {
  return instance.delete(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}/answers/${answerId}`,
  );
};

export const postUserAnswers = (
  surveyId: string,
  questionId: string,
  answerId: string,
  data?: IAnswerRequest | IResponseAnswer,
): Promise<AxiosResponse> => {
  return instance.post(
    `${config.REACT_APP_API_VERSION}/surveys/${surveyId}/questions/${questionId}/answers/${answerId}/responses`,
    data,
  );
};
