import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { IApprovedDocuments } from 'administration/interfaces/IApprovedDocs';

export const approvedEmailApi = createApi({
  reducerPath: 'approvedEmailApi',
  tagTypes: ['approvedEmail'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    searchApprovedEmails: builder.query({
      query: ({ productId, name }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/documents/emails/product/${productId}/name/${name}`,
      }),
      transformResponse: (response: { data: IApprovedDocuments[] }) => {
        return response.data;
      },
    }),
    getApprovedEmail: builder.query({
      query: ({ id }: { id: string }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/documents/emails/${id}`,
      }),
      transformResponse: (response: { data: IApprovedDocuments }) => {
        return response.data;
      },
    }),
  }),
});

export const { useLazySearchApprovedEmailsQuery, useGetApprovedEmailQuery } = approvedEmailApi;
