import { ISurveyRequest, TypeSurveyStatus } from '@administration/interfaces/ISurvey';
import { ICountryResponse } from 'administration/interfaces/ICountry';
import { setSurveyFormError } from '../slices/survey-form.slice';
import { ISurveyFormState } from '@administration/interfaces/ISurvey';
import { IQuestionRequest } from '@administration/interfaces/IQuestions';
import { IAnswerRequest } from '@administration/interfaces/IAnswers';

export const preparePayload = (surveyForm: ISurveyFormState, status: TypeSurveyStatus) => {
  const { Questions, answers, country, surveyName } = surveyForm;
  const keys = prepareKeys(country, answers);
  const surveyPayload: ISurveyRequest = {
    are_questions_ordered: true,
    // forced type since is checked in validateEntries
    country_id: country?.id as string,
    end_date: null,
    languages: country?.others_languages || ([country?.language] as string[]),
    name: surveyName,
    primary_language: country?.language as string,
    start_date: null,
    status: status,
  };

  // Question Payload
  const questionPayload: IQuestionRequest = {
    are_answers_ordered: true,
    are_answers_single_choice: Questions.are_answers_single_choice,
    country_id: country?.id as string,
    keys,
    order: 0,
    required: true,
    weight: 1,
  };

  // Answers Payload
  const answersPayload: IAnswerRequest[] = answers.map((answer) => ({
    action: answer.action ? { delay: answer.action.delay } : null,
    choices: null,
    order: answer.order,
    is_free_text: answer.is_free_text,
    is_multiple_choice: answer.is_multiple_choice,
    is_single_choice: answer.is_single_choice,
    id: answer.id,
    keys: answer.keys.map((key) => {
      const primaryLanguageKey = keys.find((k) => k.dst_lng === country?.language);
      return {
        src_lng: primaryLanguageKey?.src_lng || '',
        dst_lng: key.dst_lng,
        src_txt: primaryLanguageKey?.src_txt || '',
        dst_txt: key.dst_txt,
      };
    }),
    weight: answer.weight,
  }));
  return { surveyPayload, questionPayload, answersPayload };
};

export const prepareKeys = (
  country: ICountryResponse | null,
  answers: { keys: { dst_lng: string; src_txt: string; dst_txt: string }[] }[],
) => {
  if (!country) {
    return [];
  }

  const primaryLanguage = country.language;
  const keys: { src_lng: string; dst_lng: string; src_txt: string; dst_txt: string }[] = [];

  answers.forEach((answer) => {
    const primaryKey = answer.keys.find((key) => key.dst_lng === primaryLanguage);
    if (!primaryKey) {
      return;
    }
    answer.keys.forEach((key) => {
      keys.push({
        src_lng: primaryLanguage,
        dst_lng: key.dst_lng,
        src_txt: primaryKey.dst_txt || '',
        dst_txt: key.dst_txt,
      });
    });
  });

  return keys;
};

export const generateKeys = (country: ICountryResponse) => {
  const { language: primaryLanguage, others_languages: otherLanguages } = country;
  const keys = [];
  if (otherLanguages && otherLanguages.length > 0) {
    otherLanguages.forEach((language: string) => {
      keys.push({ src_lng: primaryLanguage, dst_lng: language, src_txt: '', dst_txt: '' });
    });
  }
  if (primaryLanguage && !otherLanguages) {
    keys.push({ src_lng: primaryLanguage, dst_lng: primaryLanguage, src_txt: '', dst_txt: '' });
  }
  return keys;
};

export const validateEntries = (
  state: ISurveyFormState,
  status: TypeSurveyStatus,
  dispatch: any,
) => {
  if (!state.country) {
    dispatch(
      setSurveyFormError({
        type: 'country',
        message: 'GLOBAL_COUNTRY_REQUIRED',
      }),
    );
    return false;
  }
  if (!state.surveyName) {
    dispatch(
      setSurveyFormError({
        type: 'surveyName',
        message: 'SURVEY_NAME_REQUIRED',
      }),
    );
    return false;
  }
  if (
    status === 'published' &&
    (!state.answers.length ||
      state.answers.some(({ keys }) => keys.some(({ dst_txt }) => !dst_txt)))
  ) {
    dispatch(
      setSurveyFormError({
        type: 'answers',
        message: 'SURVEY_ANSWER_KEYS_REQUIRED',
      }),
    );
    return false;
  }
  if (
    status === 'published' &&
    state.answers.some(
      (answer) => answer.action && !answer.action.delay?.days && !answer.action.delay?.weeks,
    )
  ) {
    dispatch(
      setSurveyFormError({
        type: 'answers',
        message: 'SURVEY_ANSWER_DELAY_REQUIRED',
      }),
    );
    return false;
  }
  return true;
};
