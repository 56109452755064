export const buildQueryParams = (searchText: string, filters: any[]): string => {
  const query =
    searchText &&
    filters
      .map((filter: string) => {
        if (filter === 'given_name' || filter === 'family_name') {
          return searchText
            .split(' ')
            .map((word: string) => `${filter}~${word}`)
            .join(' OR ');
        }
        return `${filter}~${searchText}`;
      })
      .join(' OR ');
  return query ? `(${query} AND user_type==Employee)` : `user_type==Employee`;
};
