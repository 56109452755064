import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalPayload, IModalState } from './types';

const initialState: IModalState = {
  isModalOpen: false,
  title: null,
  content: null,
  confirmAction: undefined,
  cancelFn: undefined,
  callbackFn: undefined,
};

export const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<IModalPayload>) {
      const { title, content, confirmAction, cancelFn, callbackFn } = action.payload;
      state.isModalOpen = true;
      state.title = title;
      state.content = content;
      state.confirmAction = confirmAction && confirmAction;
      state.cancelFn = cancelFn && cancelFn;
      state.callbackFn = callbackFn && callbackFn;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.content = null;
      state.title = null;
      state.confirmAction = undefined;
      state.cancelFn = undefined;
    },
  },
});

export const { openModal, closeModal } = ModalSlice.actions;
export default ModalSlice.reducer;
