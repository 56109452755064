import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISurveyRequest } from '@administration/interfaces/ISurvey';
import { IConfigurationItem } from '@administration/interfaces/IUsecases';
import { IApiStatus } from '@administration/interfaces/IApi';
import { fetchSurvey, fetchSurveys } from '../actions/usecase-survey.action';

interface IUsecaseSurvey {
  active: boolean;
  surveys: {
    values: ISurveyRequest[];
    loading: IApiStatus['loading'];
    error: IApiStatus['error'];
  };
  selectedSurvey: {
    value: ISurveyRequest | null;
    loading: IApiStatus['loading'];
    error: IApiStatus['error'];
  };
}

const initialState: IUsecaseSurvey = {
  active: false,
  surveys: {
    values: [],
    loading: 'idle',
    error: null,
  },
  selectedSurvey: {
    value: null,
    loading: 'idle',
    error: null,
  },
};

const usecaseSurvey = createSlice({
  name: 'usecaseSurvey',
  initialState,
  reducers: {
    clearSurvey: () => Object.assign({}, initialState),
    prepareSurveyForEdit: (state, action: PayloadAction<IConfigurationItem>) => {
      state.active = action.payload.active;
    },
    toggleSurveyActivation: (state) => {
      state.active = !state.active;
    },
    selectSurvey: (state, action: PayloadAction<ISurveyRequest>) => {
      state.selectedSurvey.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveys.pending, (state) => {
        state.surveys.loading = 'pending';
      })
      .addCase(fetchSurveys.fulfilled, (state, action) => {
        state.surveys.values = action.payload;
        state.surveys.loading = 'succeeded';
      })
      .addCase(fetchSurveys.rejected, (state) => {
        state.surveys.error = 'GLOBAL_ERROR';
        state.surveys.loading = 'failed';
      })
      .addCase(fetchSurvey.pending, (state) => {
        state.selectedSurvey.loading = 'pending';
      })
      .addCase(fetchSurvey.fulfilled, (state, action) => {
        state.selectedSurvey.value = action.payload;
        state.selectedSurvey.loading = 'succeeded';
      })
      .addCase(fetchSurvey.rejected, (state) => {
        state.selectedSurvey.error = 'GLOBAL_ERROR';
        state.selectedSurvey.loading = 'failed';
      });
  },
});

export const { clearSurvey, toggleSurveyActivation, selectSurvey, prepareSurveyForEdit } =
  usecaseSurvey.actions;
export default usecaseSurvey.reducer;
